<template>
  <div>
    <div class="page-wrapper-lg us-0">
      <!-- Route -->
      <div class="route__linkpath mb-4 fw-normal">
        <breadcrumbs :links="routerLinks" />
      </div>

      <!-- Card Title -->
      <div v-if="isInit" class="nexcard">
        <h4 class="text-center modal-card__title mt-3">
          {{ "Add" | multilingual }} {{ "Campaign" | multilingual }}
        </h4>

        <form class="card__inputbody pt-3" @submit.prevent="directSearch">
          <!-- User Input -->
          <div class="card_inputgroup">
            <span class="card_inputgroup__title">
              {{ "User Input*" | multilingual }}
            </span>
            <div class="w-100 d-flex flex-column">
              <div class="chat__input">
                <input autocorrect="off" autocomplete="off" type="text" class="form-control rounded w-100"
                  aria-label="UserInput" aria-describedby="UserInput" v-model="user_input" name="UserInput"
                  placeholder="Perform an audience research on AI legal tools in the United States" v-validate="'required'" />
                <button :disabled="!user_input" class="btn-send">
                  <span class="material-icons-outlined">arrow_forward</span>
                </button>
              </div>
              <span class="text-danger small" v-show="errors.has('UserInput')">
                {{ errors.first("UserInput") }}
              </span>
              <span class="text-danger small">{{ errorMessage }}</span>
            </div>
          </div>
          <div class="card_inputgroup m-0">
            <span class="card_inputgroup__title"></span>
            <span class="text-muted small">*Currently applicable to these countries: Malaysia, USA, Australia, Singapore, Canada, Vietnam, New Zealand, United Kingdom & Germany</span>
          </div>
        </form>

        <div class="card-text d-flex mt-3">
          <button @click="customSearch" class="btn btn-primary-outlined ms-auto">
            {{ "Custom Search" | multilingual }}
          </button>
          <button @click="advance" class="btn btn-primary ms-2 text-white">
            {{ "Advance" | multilingual }}
          </button>
        </div>
      </div>

      <div v-else class="nexcard">
        <h4 class="text-center modal-card__title mt-3">
          {{ "Add" | multilingual }} {{ "Campaign" | multilingual }}
        </h4>
        <form class="card__inputbody pt-3">
          <!-- Project name -->
          <div class="card_inputgroup" v-if="!$route.params.project">
            <span class="card_inputgroup__title">
              {{ "Project Name" | multilingual }}
            </span>
            <div class="w-100">
              <select class="form-select rounded w-100" aria-label="Project name" v-model="chosenClient" name="Project"
                v-validate="'required'">
                <option :value="client.id" :key="client.id" v-for="client in submitClients">
                  {{ client.name }}
                </option>
              </select>

              <span class="text-danger small" v-show="errors.has('Project')">
                {{ errors.first("Project") }}
              </span>
            </div>
          </div>

          <!-- Keyword -->
          <div class="card_inputgroup">
            <span class="card_inputgroup__title">
              {{ "Keyword" | multilingual }}
            </span>
            <div class="w-100 d-flex flex-column">
              <input autocorrect="off" autocomplete="off" type="text" class="form-control rounded w-100"
                aria-label="Keyword" aria-describedby="Keyword" v-model="keyword" name="Keyword"
                v-validate="'required'" />
              <span class="text-danger small" v-show="errors.has('Keyword')">
                {{ errors.first("Keyword") }}
              </span>
            </div>
          </div>

          <!-- Target SEO Location -->
          <div class="card_inputgroup" v-if="seo_optimized == 'true'">
            <span class="card_inputgroup__title">
              {{ "Target Location" | multilingual }}
            </span>
            <div class="w-100 d-flex flex-column">
              <select class="form-select rounded" aria-label="location" v-model="location" name="location"
                v-validate="'required'">
                <option :value="loc.id" :key="index" v-for="(loc, index) in locations">
                  {{ loc.title }}
                </option>
              </select>
              <span class="text-danger small" v-show="errors.has('location')">
                {{ errors.first("location") }}
              </span>
            </div>
          </div>

          <!-- Competitor Analysis -->
          <div class="card_inputgroup">
            <span class="card_inputgroup__title">
              Competitor Analysis
              <span id="tooltip-target-1" class="span_badge material-icons-outlined cursor-pointer">help_outline</span>
              <b-tooltip target="tooltip-target-1" trigger="hover" placement="righttop"
                variant="secondary">Optional</b-tooltip>
            </span>
            <div class="form-check form-check-inline">
              <input type="checkbox" name="competitor_analysis" id="competitor_analysis" class="form-check-input"
                v-model="competitor_analysis">
              <label for="competitor_analysis" class="form-check-label"></label>
            </div>
          </div>

          <!-- User URL -->
          <div v-show="competitor_analysis === true" class="card_inputgroup">
            <span class="card_inputgroup__title">User URL</span>
            <div class="w-100 d-flex flex-column">
              <input autocorrect="off" autocomplete="off" type="text" class="form-control rounded w-100"
                aria-label="User URL" aria-describedby="User URL" v-model="user_url" name="user_url"
                v-validate="{ required: false, url: { require_protocol: false } }" />
              <span class="text-danger small" v-show="errors.has('user_url')">
                {{ errors.first("user_url") }}
              </span>
            </div>
          </div>

        </form>

        <div class="card-text d-flex mt-3">
          <button @click="previous" class="btn btn-primary-outlined ms-auto">
            {{ "Back" | multilingual }}
          </button>
          <button @click="searchkeyword" class="btn btn-primary ms-2 text-white">
            {{ "Next" | multilingual }}
          </button>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
/* eslint-disable */
import nexIntelligence from '@/store/NexIntelligence';
import breadcrumbs from '@/components/Breadcrumbs';

export default {
  components: {
    breadcrumbs
  },
  data() {
    return {
      seo_optimized: 'true',
      competitor_analysis: false,
      location: '',
      locations: JSON.parse(localStorage.locations),
      keyword: '',
      user_url: '',
      chosenClient: '',
      submitClients: [],
      isInit: true,
      user_input: '',
      init_error: ''
    };
  },
  computed: {
    routerLinks() {
      return [
        { to: '/dashboard', texts: ['Dashboard'] },
        {
          to: {
            name: 'feature_pagination_table',
            params: { feature: 'nex_intelligence' }
          },
          texts: ['NexIntelligence']
        },
        { to: '', texts: ['Create', 'Campaign'] },
      ];
    },
    errorMessage() {
      if (this.init_error) {
        return this.init_error;
      } else {
        return '';
      }
    }
  },
  methods: {
    async searchkeyword() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) return;

      const payload = {
        keywords: this.keyword,
        location_id: this.location,
        client_id: this.chosenClient,
      };
      if (this.user_url) payload.user_url = this.user_url;

      const loader = this.$loading.show();
      try {
        const { data } = await nexIntelligence.addCampaign(payload);

        if (data) {
          this.$toast.success(data.message);
          this.$router.push({
            name: 'intelligence_camp',
            params: { id: data.data.id },
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        loader.hide();
      }
    },
    previous() {
      this.isInit = true;
    },
    async directSearch() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) return;

      const payload = { user_input: this.user_input };
      try {
        const { data } = await nexIntelligence.initCampaign(payload);
        if (data.message === 'Successful') {
          if (JSON.parse(localStorage.getItem('userInfo')).company_name === 'NexMind AI') {
            this.chosenClient = this.submitClients.find(item => item.id === 479).id;
          } else {
            this.chosenClient = this.submitClients[0].id;
          }
          this.keyword = data.data.topic;
          if (data.data.url) {
            this.competitor_analysis = true;
            this.user_url = data.data.url;
          }
          this.location = this.locations.find((item) => {
            if (data.data.country === 'United States') {
              return item.title === 'USA';
            } else {
              return item.title === data.data.country;
            }
          }).id;
        } else {
          this.init_error = data.message;
        }
      } catch (error) {
        console.log(error);
      } finally {
        if (this.init_error) {
          return;
        } else {
          this.init_error = '';
          this.searchkeyword();
        }
      }
    },
    customSearch() {
      this.user_input = '';
      this.keyword = '';
      this.chosenClient = '';
      this.location = '';
      this.competitor_analysis = false;
      this.user_url = '';
      this.isInit = false;
    },
    async advance() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) return;

      const payload = { user_input: this.user_input };
      const loader = this.$loading.show();
      try {
        const { data } = await nexIntelligence.initCampaign(payload);
        if (data.message === 'Successful') {
          if (JSON.parse(localStorage.getItem('userInfo')).company_name === 'NexMind AI') {
            this.chosenClient = this.submitClients.find(item => item.id === 479).id;
          } else {
            this.chosenClient = this.submitClients[0].id;
          }
          this.keyword = data.data.topic;
          if (data.data.url) {
            this.competitor_analysis = true;
            this.user_url = data.data.url;
          }
          this.location = this.locations.find((item) => {
            if (data.data.country === 'United States') {
              return item.title === 'USA';
            } else {
              return item.title === data.data.country;
            }
          }).id;
          this.isInit = false;
          this.init_error = '';
          this.$toast.success(data.message);
        } else {
          this.init_error = data.message;
        }
      } catch (error) {
        console.log(error);
      } finally {
        loader.hide();
      }
    }
  },
  created() {
    const clients = JSON.parse(localStorage.nexrank_client);
    this.submitClients = clients.sort((a, b) => a.name.localeCompare(b.name));
  },
};
</script>

<style scoped>
.chat__input {
  display: flex;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
}

input::placeholder {
  font-size: 0.9em; /* Adjust the font size as needed */
  color: #cccccc; /* Light gray color */
}

.chat__input input {
  border: none;
  height: 38px;
  width: 100%;
  box-shadow: none;
  outline: none;
}

.chat__input .btn-send {
  padding: 0px;
  margin: 0px;
  height: 38px;
}

.chat__input .btn-send span {
  background-color: var(--color-primary);
  color: white;
  font-size: 38px;
  border-radius: 0 0.375rem 0.375rem 0;
}

.chat__input .btn-send:hover span {
  background-color: #0379e0;
  color: white;
  transition: 300ms ease-out;
}
</style>